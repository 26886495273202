/* NAVBAR  */

html {
    overflow: hidden;
    height: 100%;
    scroll-behavior: smooth;
    /* scroll-snap-type: y mandatory; */
}

body {
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    scroll-behavior: smooth;
}

body * {
    box-sizing: border-box;
}

.loading {
    width: 100%;
    height: 100%;
    justify-content: center;
}

.home {
}

/* .portfolio-container, .navbar, .resume-container, . , .artContentabout-container, .githubHeader, footer{
  scroll-snap-align: start;
} */

header {
    background-size: cover;
    background-position: 50% 20%;
    height: 100vh;
    min-height: 700px;
}

header nav {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: auto;
    height: 20vh;
    letter-spacing: 3px;
    font-weight: 500;
}

header nav a {
    color: white !important;
}

a:visited,
a:active,
a:focus,
a:focus-within {
    color: rgb(255, 255, 255);
}

a:hover {
    color: rgb(46, 24, 24);
}

.fixednav {
    min-width: 100%;
}

.fixednav nav {
    overflow: hidden;
    position: fixed;
    top: 0;
    background-color: #123852;
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    height: 8vmin;
    width: 100%;
    letter-spacing: 3px;
    font-weight: 500;
    z-index: 2;
}

.fixed-navbar {
    width: 100%;
    z-index: 1000;
    position: fixed;
    height: max-content;
    top: 0;
    justify-content: center;
    background-color: rgba(21, 51, 71, 0.568);
}

.fixed-navbar li {
    padding: 10px;
}

.fixednav nav a {
    margin-left: 40px;
    margin-right: 40px;
    color: white !important;
}

.hello {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    height: 80vh;
    width: 100%;
    margin: 0 auto 0 auto;
    vertical-align: middle;
}

.hello h1 {
    color: white;
    font-size: 10vmin;
}

.hello h3 {
    color: rgba(255, 255, 255, 0.664);
    font-style: italic;
    padding-bottom: 20px;
}

.hello p {
    color: white;
    font-size: 5vmin;
}

.hello .myresume {
    color: rgba(255, 255, 255, 0.664);
    font-size: 2vmin;
    letter-spacing: 5px;
    font-weight: bold;
}

.hello .links {
    color: white;
}

.hello .links * {
    margin: 5px;
}

.hello .links a:hover {
    color: white;
}

.hello .links a {
    color: white !important;
}

@media screen and (max-width: 1024px) {
    header nav {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    header nav {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .header-nav li {
        display: none;
    }

    .header-nav {
        flex-direction: column;
    }
}

.header-nav-backgorunded {
}

.header-nav-hamburger {
    position: absolute;

    top: 5px;
    right: 10px;
}

@media screen and (min-width: 600px) {
    .header-nav-hamburger {
        display: none;
    }
}

.header-nav-li-open-menu {
    display: block !important;
}

/* Portfolio */

.portfolio {
    overflow: hidden;
    position: relative;
    margin: auto;
    padding: 0;
    max-width: 800px;
}

.portfolio .portfolioTitle {
    background: linear-gradient(to right, #5a2222, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding: 50px;
}

.portfolio-container {
    width: 100%;
    background: rgb(51, 51, 51);
    background: radial-gradient(
        circle,
        rgb(2, 93, 119) 0%,
        rgb(24, 34, 58) 100%
    );
}

.resume-container {
    width: 100%;
    background: #c3c4c5;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
}

.cards .card {
    object-fit: cover;
    flex: 0 1 40vmax;
    margin: 30px;
    max-width: 100%;
}

.artTitle {
    text-align: center;
}

.games-container {
    max-width: 100%;
}

.card-carousel {
    margin: auto;
    max-width: 786px;
}

.card-carousel .slider .slide {
    z-index: -1;
}

.card-carousel .slider .slide.selected {
    z-index: 1;
}

.carousel-image-div img {
    width: auto !important;
    max-width: 100%;
    height: auto !important;
}

.card-carousel img {
    padding: 0px 10px;
}

.cards .card img {
    max-height: 500px;
    max-width: 100%;
}

.card > img {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    align-self: center;
}

.cards .card img:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.slide.selected {
    width: 800px;
}

.slider-wrapper {
    background-color: rgba(255, 255, 255, 0.109);
}

.cards .card {
    color: white;
    text-align: center;
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .cards .card {
        flex: 0 1 100vmax;
        margin-left: 0px;
        margin-right: 0px;
    }

    .cards .card h2,
    .cards .card p,
    .cards .card a {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.cards .card a button {
    margin: 10px;
}

.cards .card h2 {
    margin: 10px;
}

.portfolio svg {
    display: block;
    bottom: 0;
}

/* Resume */

.resume {
    background-color: #c3c4c5;
    min-height: 100vh;
    max-width: 800px;
    margin: auto;
}

.resume h1.mainResumeTitle {
    background: linear-gradient(to right, #ff8989, #131212);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding-top: 10vmin;
    padding-bottom: 10vmin;
}

.resume .resumeTitle,
.portfolio .portfolioTitle2 {
    font-weight: bolder;
    font-size: 40px;
}

.resume .infoCards {
    margin: 20px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-around;
    text-align: left;
}

.education,
.experience {
    display: flex;
    flex-direction: column;
    justify-content: safe;
    flex: 1;
    margin-left: 5%;
}

.resume .info {
    background-color: #2e3031;
    color: rgb(202, 194, 194);
    margin: 25px 0 25px 0;
    width: 90%;
    border-radius: 3px;
}

.info .date,
.info .place {
    padding: 15px;
}

@media screen and (max-width: 800px) {
    .infoCards {
        flex-direction: column;
    }
}

.info h1 {
    padding: 15px;
    color: #a5b1be;
}

/* About */

.about svg.aboutFirstSvg {
    position: relative;
    left: 0;
    top: 0;
    z-index: 0;
}

.about svg.aboutSecondSvg {
    position: relative;
    margin-bottom: -5px;
}

.about {
    height: 100%;
    max-width: 1000px;
    margin: auto;
}

.about-container,
.artContainer {
    height: 100vh;
    background: rgb(51, 51, 51);
    background: radial-gradient(
        circle,
        rgb(2, 93, 119) 0%,
        rgb(24, 34, 58) 100%
    );
}

.artContainer {
    min-height: 100vh;
}

.aboutContainer,
.artContainer {
    height: 100%;
    display: flex;
    color: #c3c4c5;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.aboutContainer img {
    padding: 20px;
    max-width: 100%;
}

.aboutContent,
.artContent {
    padding: 20px;
    padding-bottom: 50px;
    max-width: 800px;
}

.aboutContainer .aboutContent h1 {
    background: linear-gradient(to right, #5e5e5e, #ffd6d6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.aboutContainer .aboutContent p {
    width: 100%;
    text-align: left;
    font-size: 24px;
}

.aboutContainer .meSpan {
    font-weight: bolder;
    font-size: 28px;
}

@media screen and (max-width: 1000px) {
    .aboutContainer {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .aboutContainer img {
        max-width: 100%;
    }

    .aboutContent {
        padding: 0 50px 50px 50px;
    }
}

@media screen and (max-width: 700px) {
    .aboutContainer .aboutContent p {
        width: 80%;
    }

    .aboutContainer img {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Github repos */

.github,
.githubHeader {
    background-color: #c3c4c5;
}

.githubHeader .githubTitle h1,
.artTitle h1 span {
    text-align: center;
    background: linear-gradient(to right, #ffffff, #ff9999);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 40px;
    font-size: 5vmin;
}
.artTitle {
    padding-top: 30px;
    font-size: 3vmin !important;
}
.artTitle h1 span {
    font-size: 8vmin !important;
}

.githubHeader .githubInfo {
    display: flex;
    width: 80%;
    margin-left: 10%;
    flex-wrap: wrap;
    justify-content: center;
}

.githubHeader .githubInfo img {
    padding: 20px;
    width: 200px;
    height: 200px;
}

.githubHeader .githubInfo .nameandfollower {
    margin: 40px;
    align-content: center;
    color: black !important;
}

.githubHeader .githubInfo .nameandfollower * {
    color: black !important;
    text-align: center;
}

/* github repos */

.allrepos {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10%;
    width: 80%;
    justify-content: center;
}

.repo {
    margin: 10px;
    width: 400px;
    background-color: #aaaaaa;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.repo:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.innerRepo {
    padding: 10px;
}

.repo p {
    padding: 10px;
}

.repo a {
    color: black;
}

a {
    text-decoration: none;
    outline: 0;
}

.repo a:active,
.repo a:focus,
.repo a:visited,
.repo a:focus-within {
    color: black;
}

.repo a:hover {
    color: rgb(128, 88, 88);
}

/* Footer */

footer {
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c3c4c5;
}

.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.notFound a {
    color: rgb(41, 192, 252);
}

.games-container {
    min-height: 100vh;
    background: rgb(51, 51, 51);
    background: radial-gradient(
        circle,
        rgb(2, 93, 119) 0%,
        rgb(24, 34, 58) 100%
    );
}

.restartButton:hover {
    background-color: #bca0dc !important;
}

.restartButton:active {
    background-color: #9969d0 !important;
}

.tetris {
    padding: 20px;
    height: 100vh;
    text-align: center;
    background: rgb(111, 105, 208);
    background: linear-gradient(
        90deg,
        rgba(111, 105, 208, 1) 0%,
        rgba(206, 105, 208, 1) 96%
    );
}

.snakeAI {
    padding: 20px;
    height: 100vh;
    text-align: center;
    background: rgb(111, 105, 208);
    background: linear-gradient(
        90deg,
        rgba(111, 105, 208, 1) 0%,
        rgba(206, 105, 208, 1) 96%
    );
}

.snakeAI-inputs {
    max-width: 400px;
    text-align: left;
    margin: auto;
}

.githubStartButton:hover {
    border: 2px solid rgb(178, 178, 178) !important;
    background-color: #2d333c !important;
}

.githubStartButtonA {
    all: unset;
    cursor: pointer;
    color: white !important;
}

.tetrisModeButtons {
    color: white;
    position: absolute;
    top: 260px;
    background-color: rgb(189, 95, 169);
    border-radius: 20px;
    margin-left: 10px;
}

.tetrisModeButton {
    all: unset;
    padding: 10px;
}
.tetrisModeButton:hover {
    cursor: pointer;
    background-color: rgb(167, 83, 149);
}

.tetrisModeButton:active {
    background-color: rgb(130, 65, 116);
}

.choosedTetrisMode {
    background-color: rgb(218, 141, 201);
}
